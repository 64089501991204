import React from "react"
import styled from '@emotion/styled'
import Image from "./image.js"

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    border-top: 4px solid #540B0E;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 4em 1.5em;
    color: #545454;
    display: flex;
    flex-direction: row;
  `

  const Title = styled.h3`
    font-size: 2em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .35em;

    @media (min-width: 1024px) {
      font-size: 2.5em;
      margin-bottom: .5em;
    }
  `

  const Left = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    p {
      font-family: "Roboto", sans-serif;
      font-size: 1.12em;
      margin-bottom: 1.25em;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
    }

    ul {
      display: flex;
      flex-direction: column;
      margin-top: .5em;
      list-style-type: circle;
      margin-bottom: 4em;

      li {
        margin-left: 1em;
        font-family: "Roboto", sans-serif;
        font-size: 1.12em;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        }
        a {
          transition: color .3s ease;
          &:hover {
            color: #000;
            transition: color .3s ease;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      padding-right: 4em;
    }
  `

  const Right = styled.div`
    display: none;

    @media (min-width: 1024px) {
      display: block;
      margin-top: 1em;
    }
  `

  const ImageWrapper = styled.div`
    width: 425px;
    height: auto;
  `

  return (
        <Wrapper>
          <InnerWrapper>
            <Left>
              <Title>Market Rules & Regulations</Title>
              <ul>
                <li><a href="./docs/ffm-c19_policies.pdf">COVID-19 Health & Safety Policies</a></li>
                <li><a href="./docs/ffm-policies-meadow_lake.pdf">Meadow Lake Market Rules & Regulations</a></li>
                <li><a href="./docs/ffm-policies-fairview_elem.pdf">Fairview Elementary Market Rules & Regulations</a></li>
                <li><a href="./docs/ffm-policies-memorial_park.pdf">Memorial Park Market Rules & Regulations</a></li>
                <li><a href="./docs/ffm-cat_descriptions_policy.pdf">Category Product Descriptions</a></li>
                <li><a href="./docs/ffm-busking_policy.pdf">Fairview Farmer Market Busking Policy</a></li>
              </ul>
              <Title>Frequently Asked Questions</Title>
              <ul>
                <li><a href="./docs/ffm-seasonal_vendor_faqs.pdf">Seasonal vendor FAQs</a></li>
                <li><a href="./docs/ffm-weekly_vendor_faqs.pdf">Weekly vendor FAQs</a></li>
                <li><a href="./docs/ffm-c19_vendor_faqs.pdf">COVID-19 vendor FAQs</a></li>
                <li><a href="./docs/ffm-snap_token_faqs.pdf">SNAP/EBT Token FAQs</a></li>
                <li><a href="./docs/ffm-non-food_vendor_faqs.pdf">Non-food item vendor FAQs</a></li>
              </ul>
              <Title>Vendor Applications</Title>
              <ul>
                <li><a href="./docs/ffm-2020-sesonal_new_vendor_app.pdf">2020 Season Application &mdash; New Vendors</a></li>
                <li><a href="./docs/ffm-2020-sesonal_returning_vendor_app.pdf">2020 Season Application &mdash; Returning Vendors</a></li>
                <li><a href="./docs/ffm-2020-weekly_vendor_application.pdf">2020 Weekly Application</a></li>
                <li><a href="./docs/ffm-2020-non-profit_application.pdf">2020 Non-profit Application</a></li>
                <li><a href="./docs/ffm-2020-busking_application.pdf">2020 Busking Application</a></li>
                <li><a href="./docs/ffm-2020-weekly_sponsor_application.pdf">2020 Weekly Sponsor Application</a></li>
                <li><a href="./docs/ffm-2020-snap_token_application.pdf">2020 SNAP/EBT Token Application</a></li>
              </ul>
            </Left>
            <Right>
              <ImageWrapper>
                <Image />
              </ImageWrapper>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
