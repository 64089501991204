import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/growers/hero"
import Intro from "../components/growers/intro"


const Visit = () => (
  <Layout>
    <SEO title="Information for Growers" />
    <Hero />
    <Intro />
  </Layout>
)

export default Visit
